<template>
    <div class="modal" v-if="modelValue" :class="{active : modelValue}">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Edit <span>Template</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal" type="button"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <Form v-slot="{ errors }" ref="rename-form-form">
                        <div class="form_grp" v-if="templateType == 'email'">
                            <div class="group_item">
                                <label class="input_label">Email Subject</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.subject }">
                                    <Field autocomplete="off" type="text" name="subject" id="email_subject" v-model="form.subject" placeholder="Type Subject" rules="required" />
                                </div>
                                <ErrorMessage name="subject" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp" v-if="templateType == 'email'">
                            <div class="group_item">
                                <label class="input_label">Email Content</label>
                                <div class="editorWpr mb-4" :class="{ 'has-error': errors.content }">
                                    <Field autocomplete="off" name="content" v-model="form.email_content" :class="{ 'has-error': errors.content }" rules="required_editor">
                                        <redactor v-model="form.email_content" :default-value="template.email_content" :reset-watcher="resetToDefaultWatcher" :default-value-watcher="editorDefaultValueWatcher" name="content" />
                                    </Field>
                                </div>
                                <ErrorMessage name="content" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp" v-if="templateType == 'sms'">
                            <div class="group_item">
                                <label class="input_label">SMS Content</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.sms_content }">
                                    <Field autocomplete="off" name="content" v-model="form.sms_content" :class="{ 'has-error': errors.content }" rules="required">
                                        <textarea v-model="form.sms_content" />
                                    </Field>
                                </div>
                                <ErrorMessage name="content" class="text-danger" />
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            <div class="modal_footer">
                <button type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                <button type="button" class="btn save_btn" @click="handleImport(form)">Import</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'

    export default{
        name: 'EditTemplate',

        data () {
            return {
                form: {
                    subject: '',
                    email_content: '',
                    sms_content: '',
                    sms_media: '',
                },
                resetToDefaultWatcher: 0,
                editorDefaultValueWatcher: 0,
            }
        },

        props: {
            modelValue: Boolean,
            template: Object,
            templateType: String,
            handleImport: Function,
        },

        emit: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        watch: {
            modelValue (val) {
                const vm = this;

                if (val) {
                    vm.form.subject       = vm.template.subject;
                    vm.form.email_content = vm.template.email_content;
                    vm.form.sms_content   = vm.template.sms_content;
                    vm.form.sms_media     = vm.template.sms_media;

                    setTimeout(function () {
                        vm.resetToDefaultWatcher++;
                        vm.editorDefaultValueWatcher++;
                    }, 10);
                } else {
                    vm.form.subject       = '';
                    vm.form.email_content = '';
                    vm.form.sms_content   = '';
                    vm.form.sms_media     = '';

                    setTimeout(function () {
                        vm.resetToDefaultWatcher++;
                        vm.editorDefaultValueWatcher++;
                    }, 10);
                }
            },
        },

        methods: {
            closeModal () {
                const vm = this;
                vm.$emit('update:modelValue', false);
            },
        }
    }
</script>

<style scoped>
    .form_grp textarea{
        height: 200px;
        font-size: 13px;
        line-height: 20px;
    }
</style>